export const TerminalType = {
	CCC: "ccc",
	SHELL: "shell",
} as const;

export const TerminalEntryType = {
	CMD: "cmd",
	RTRACE: "rtrace",
	JSON_TRACE: "jsonTrace",
} as const;

export const SessionIdGeneral = "general";

export const downloadData = (filename: string, type: string, data: string) => {
	const blob = new Blob([data], { type: type });
	const url = URL.createObjectURL(blob);
	const tempLink = document.createElement("a");
	tempLink.href = url;
	tempLink.setAttribute("download", filename);
	tempLink.click();
};
