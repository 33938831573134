import { memo } from "react";
import { Paper, CircularProgress } from "@mui/material";

type Props = {
	loadingMsg?: string;
	center?: boolean;
};

const CenterCircularProgress = ({ loadingMsg = undefined, center = false }: Props) => (
	<Paper
		sx={{
			position: "fixed",
			top: "50%",
			left: "50%",
			display: "flex",
			flexDirection: "column",
			justifyContent: loadingMsg ? "space-between" : "center",
			alignItems: "center",
			marginLeft: center ? undefined : `${300 / 2}px`, // 300px = Menu width
			marginTop: `${64 / 2}px`, // 64px/56px = AppBar height
			padding: "16px",
			width: "122px",
			height: "112px",
			transform: "translate(-50%, -50%)",
		}}
	>
		<CircularProgress disableShrink={true} />
		{loadingMsg && <span>{loadingMsg}</span>}
	</Paper>
);

export default memo(CenterCircularProgress);
