import { useTranslation } from "react-i18next";
import Ansi from "ansi-to-react";
import { Link } from "@mui/material";
// cmps
import Na from "../na";
// services
import Constants from "../../services/constants";
import { TerminalEntryType } from "../../services/terminal";
// types
import type { MouseEvent } from "react";
import type { TerminalEntry as TerminalEntryT } from "../../types/terminal";

const ENTRY_COLOR = {
	[Constants.Direction.TX]: "#00aa00",
	[Constants.Direction.RX]: "#dd0000",
	default: "inherit",
} as const;

const getRequstId = (entryData: TerminalEntryT["data"]) => {
	if (entryData.requestId) {
		return `[..${entryData.requestId.substring(entryData.requestId.length - 4)}] `;
	} else if (entryData.responseId) {
		return `[..${entryData.responseId.substring(entryData.responseId.length - 4)}] `;
	}
	return "";
};

const getAdditionalInfo = (entryData: TerminalEntryT["data"]) => {
	if (entryData.action) {
		switch (entryData.action) {
			case "gatewayAction":
				return `${entryData.action}[${entryData.module}:${entryData.function}]`;
			case "sendGeneralCmd":
				return `${entryData.action}[Cl:${entryData.clusterId}][Ep:${entryData.endpoint}][CmdId:${entryData.cmdId}][id:${entryData.deviceId}]`;
			case "sendActionCmd":
				return `${entryData.action}[Cl:${entryData.clusterId}][Ep:${entryData.endpoint}][CmdId:${entryData.cmdId}][id:${entryData.deviceId}]`;
			case "extendDelayedAction":
				return `${entryData.action}[Cl:${entryData.clusterId}][Ep:${entryData.endpoint}][CmdId:${entryData.cmdId}][id:${entryData.deviceId}][delay:${entryData.delayed}]`;
			default:
				return entryData.action;
		}
	}
	if (entryData.info) {
		if (entryData.status === "error") {
			return `${entryData.info}[Error:${entryData.error ?? "unknown error"}]`;
		} else {
			switch (entryData.info) {
				case "gatewayAction":
					return `${entryData.info}[${entryData.module}:${entryData.function}]`;
				case "sendGeneralCmd":
					if (entryData.sourceAction) {
						return `${entryData.info}[Cl:${entryData.clusterId}][Ep:${entryData.sourceAction.endpoint}][CmdId:${entryData.sourceAction.cmdId}][id:${entryData.sourceAction.deviceId}]`;
					} else {
						return `${entryData.info}[Cl:${entryData.clusterId}]`;
					}
				case "sendActionCmd":
					if (entryData.sourceAction) {
						return `${entryData.info}[Cl:${entryData.clusterId}][Ep:${entryData.sourceAction.endpoint}][CmdId:${entryData.sourceAction.cmdId}][id:${entryData.sourceAction.deviceId}]`;
					} else {
						return `${entryData.info}[Cl:${entryData.clusterId}]`;
					}
				case "extendDelayedAction":
					if (entryData.sourceAction) {
						return `${entryData.info}[Cl:${entryData.sourceAction.clusterId}][Ep:${entryData.sourceAction.endpoint}][CmdId:${entryData.sourceAction.cmdId}][id:${entryData.sourceAction.deviceId}][delay:${entryData.sourceAction.delayed}]`;
					} else {
						return entryData.info;
					}
				case "attributeReport":
					return `${entryData.info}[Cl:${entryData.cluster_id}][Ep:${entryData.endpoint}][Name:${entryData.deviceName}][Type:${entryData.defaultTextKey}]`;
				case "deviceReport":
					return `${entryData.info}[Id:${entryData.data.id}][Rocid:${entryData.data.rocId}][DeviceType:${entryData.data.attributes["Device Type"]}]`;
				case "gatewayInfo":
					return `${entryData.info}[ZB:${entryData.data.zigbee}][ZW:${entryData.data.zwave}][WIFI:${entryData.data.wireless}][Pair:${entryData.data.pairing}][Group:${entryData.data.group}]`;
				case "newDeviceJoining":
					if (entryData.rocid) {
						return `${entryData.info}[id:${entryData.id}][pairState:${entryData.pairState}][Rocid:${entryData.rocid}]`;
					} else {
						return `${entryData.info}[id:${entryData.id}][pairState:${entryData.pairState}]`;
					}
				default:
					return entryData.info;
			}
		}
	}
	return "";
};

type Props<TE extends TerminalEntryT = TerminalEntryT> = {
	entry: TE;
	promptSymbol: string;
	timestampEnabled: boolean;
	onJsonTraceClick: (entry: TE) => void;
};

const TerminalEntry = ({ entry, promptSymbol, timestampEnabled, onJsonTraceClick }: Props) => {
	const { t } = useTranslation();

	const handeJsonTraceClick = (event: MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		onJsonTraceClick(entry);
	};

	switch (entry.type) {
		case TerminalEntryType.CMD:
			return `${promptSymbol} ${entry.data}`;
		case TerminalEntryType.RTRACE:
			return <Ansi>{entry.data}</Ansi>;
		case TerminalEntryType.JSON_TRACE:
			return (
				<Link
					href="#"
					onClick={handeJsonTraceClick}
					style={{ color: ENTRY_COLOR[entry.dir ?? "default"] }}
				>
					{timestampEnabled ? `${entry.ts.substring(11, entry.ts.length)} ` : <Na />}
					{(entry.dir === undefined) ? entry.data : `${t(`gateway.${entry.dir}`)}:${getRequstId(entry.data)}${getAdditionalInfo(entry.data)}`}
				</Link>
			);
		default:
			console.warn("Unknown Entry type:", entry);
			return entry.data;
	}
};

export default TerminalEntry;
