// contexts
import { UserDataProvider } from "./scripts/context/UserDataContext";
// types
import type { ReactElement } from "react";

type Props = {
	children: ReactElement;
};

const OnlineProviders = ({ children }: Props) => (
	<UserDataProvider>
		{children}
	</UserDataProvider>
);

export default OnlineProviders;
