// services
import Constants from "./constants";
import RocWs from "./roc-ws";
// types
import type { GatewayId } from "../types/gateway";
import type { CmdLogin, MsgResponseLogin } from "../types/cc";

class CC extends RocWs<typeof Constants.WsType.CC> {

	#gatewayId: GatewayId | undefined = undefined;

	constructor() {
		super(Constants.WsType.CC);
	}

	public override setLoginPayload(loginPayload: Partial<CmdLogin>) {
		this.#gatewayId = loginPayload.tap;
		super.setLoginPayload(loginPayload);
	}

	public get gatewayId() {
		return this.#gatewayId;
	}

	public override async postLogin(msg: MsgResponseLogin): Promise<void> {
		await super.postLogin(msg);
	}

}

export default CC;
