import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
// contexts
import { useServerOption } from "../../context/ServerOptionContext";
// services
import { downloadData } from "../../services/terminal";
import { icons } from "@local/theme";
// types
import type { UserId } from "../../types/user";
import type { GatewayId } from "../../types/gateway";
import type { TerminalEntries, SessionId, Traces } from "../../types/terminal";

type Props = {
	userId?: UserId;
	gatewayId?: GatewayId;
	traces: Traces;
	terminalEntries: TerminalEntries;
	sessionId?: SessionId;
	onLoadFile: (file: File) => void;
};

const SaveLoadCCCTrace = ({ userId = undefined, gatewayId = undefined, traces, terminalEntries, sessionId = undefined, onLoadFile }: Props) => {
	const { t } = useTranslation();
	const { channel } = useServerOption();

	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);

	const handleStoreTraceInfoClick = () => {
		const dateTime = new Date().toISOString().substring(0, 19).replace(/:/g, "");
		if (sessionId) {
			const trace = traces.find((trace) => (trace.sessionId === sessionId));
			const jsonData = JSON.stringify(trace, null, 2);
			downloadData(`${channel}_${userId ?? ""}_${sessionId}_${dateTime}.json`, "application/json", jsonData);
		} else if (gatewayId) {
			const jsonData = JSON.stringify(terminalEntries, null, 2);
			downloadData(`${channel}_${gatewayId.replace(/:/g, "")}_${dateTime}.json`, "application/json", jsonData);
		}
	};

	const handleUploadClick = () => {
		setShowUploadDialog(false);
		onLoadFile(selectedFile!);
	};

	return (
		<>
			<Button
				variant="contained"
				size="small"
				startIcon={<icons.SaveIcon />}
				onClick={handleStoreTraceInfoClick}
				style={{ marginLeft: "12px" }}
			>
				{t("gateway.saveSession")}
			</Button>
			<Button
				variant="contained"
				size="small"
				startIcon={<icons.Upload />}
				onClick={() => (setShowUploadDialog(true))}
				style={{ marginLeft: "12px" }}
			>
				{t("gateway.loadSession")}
			</Button>
			<Dialog
				open={showUploadDialog}
				fullWidth={true}
			>
				<DialogTitle>{t("gateway.uploadTitle")}</DialogTitle>
				<DialogContent>
					<input
						className="session-uploader"
						type="file"
						name="Load session"
						onChange={(event) => (setSelectedFile(event.target.files?.[0] ?? null))}
					/>
				</DialogContent>
				<DialogActions>
					<Button disabled={!selectedFile} onClick={handleUploadClick}>{t("gateway.upload")}</Button>
					<Button color="inherit" onClick={() => (setShowUploadDialog(false))}>{t("dialog.cancel")}</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default SaveLoadCCCTrace;

