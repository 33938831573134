import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
// types
import type { JsonValue } from "type-fest";

type Props = {
	jsonString: string;
	onSend: (data: JsonValue) => void;
	onClose: () => void;
};

const jsonInvalid = (jsonString: string) => {
	try {
		JSON.parse(jsonString);
		return false;
	} catch (error) {
		console.info("Invalid JSON", error);
		return true;
	}
};

const TerminalJsonEditor = ({ jsonString, onSend, onClose }: Props) => {
	const theme = useTheme();
	const { t } = useTranslation();

	const [json, setJson] = useState(jsonString);
	const [invalid, setInvalid] = useState(jsonInvalid(jsonString));

	useEffect(() => {
		setJson(jsonString);
	}, [jsonString]);

	const handleJsonChange = (event) => {
		const newJsonString = event.target.value;
		setJson(newJsonString);
		setInvalid(jsonInvalid(newJsonString));
	};

	const handleSendClick = () => {
		const data = JSON.parse(json) as JsonValue;
		onSend(data);
	};

	const handleCloseClick = () => {
		setInvalid(false);
		onClose();
	};

	return (
		<div style={{ width: "30%" }}>
			<TextField
				placeholder="{}"
				fullWidth={true}
				multiline={true}
				value={json}
				onChange={handleJsonChange}
				error={invalid}
				style={{ height: "calc(100% - 49px)" }}
				InputProps={{ style: { height: "100%", padding: 0, backgroundColor: (theme.palette.mode === "dark") ? grey[800] : grey[100] }, disableUnderline: true }}
				inputProps={{ style: { height: "100%", overflow: "hidden scroll" } }}
			/>
			<div style={{ display: "flex", justifyContent: "flex-end", margin: "6px" }}>
				<Button
					variant="contained"
					disabled={invalid}
					onClick={handleSendClick}
					style={{ marginRight: "8px" }}
				>
					{t("gateway.send")}
				</Button>
				<Button variant="contained" color="neutral" onClick={handleCloseClick}>{t("gateway.close")}</Button>
			</div>
		</div>
	);
};

export default TerminalJsonEditor;
