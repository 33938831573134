import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
// cmps
import CenterCircularProgress from "./scripts/cmp/CenterCircularProgress";
// services
import { checkIsAuthenticated } from "./scripts/services/authenticator";
import User from "./scripts/services/user";
import Gupport from "./scripts/services/gupport";

const RouteListener = () => {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkIsAuthenticated().then(async (isAuthenticated) => {
			if (isAuthenticated) {
				await Gupport.connect();
			} else {
				await User.logoutCleanup();
				navigate("/login");
			}
		}).catch((error) => {
			console.error("Failed to check if authenticated", error);
		}).finally(() => {
			setLoading(false);
		});
	}, [navigate]);

	if (loading) {
		return <CenterCircularProgress center={true} />;
	}

	return <Outlet />;
};

export default RouteListener;
